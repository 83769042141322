import { graphql, useStaticQuery } from "gatsby"
import moment from "moment"

const useOffersData = () => {
  const offersData = useStaticQuery(graphql`
    query {
      offers: allSanityOffer(filter: { isExpiredAtBuildTime: { eq: false } }) {
        nodes {
          ...Offer
        }
      }
    }
  `)
  const now = moment()
  const offers = offersData?.offers?.nodes
    ?.map(offer => {
      const endDate = moment(offer?.endDate)?.endOf("day")
      const today = moment()?.startOf("day")
      const isExpired = endDate?.isSameOrBefore(today)

      if (isExpired) return null
      return {
        ...offer,
        isExpired: isExpired,
        isExpiringSoon: moment(offer?.endDate)?.diff(now, "days") <= 7,
      }
    })
    ?.filter(offer => offer)
  return offers ? offers : []
}

export default useOffersData
