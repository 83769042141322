import React, { useContext } from "react"
import tw from "twin.macro"
import { LocalStorageClient } from "../../../clients/LocalStorageClient"
import { ContactContext } from "../../../contexts/Contact"
import { setActiveView } from "../../../contexts/Contact/actions"
import { LanguageContext } from "../../../contexts/Language"
import { LocationContext } from "../../../contexts/Location"
import { useTealiumContext } from "../../../contexts/Tealium"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { Button } from "../../atoms/Button"
import { ViewProps } from "./ContactDealer.d"
import FormPage from "./FormPage"
import { tealiumNavClick } from "./TealiumHelpers"

const ConfirmZip: React.FC<ViewProps> = ({ active }) => {
  const [{ zip }] = useContext(LocationContext)
  const [{}, dispatch] = useContext(ContactContext)
  const { _ } = useContext(LanguageContext)
  // Tealium
  const { trackTealEvent } = useTealiumEvent()
  const { contactDealerData } = useTealiumContext()

  if (!active) return null

  return (
    <FormPage
      active={active}
      showButtons={false}
      heading={_("Confirm Your Zip Code")}
      css={tw`text-2xl font-semibold`}
      showFormHeading={false}
    >
      <p css={tw`my-5`}>{zip}</p>
      <div css={tw`flex gap-4`}>
        <Button
          negative
          onClick={e => {
            dispatch(setActiveView("DealerLocationView"))
            tealiumNavClick(
              "Confirm Your Zip Code",
              "Change:zip",
              trackTealEvent,
              contactDealerData,
            )
            e.preventDefault()
          }}
          css={tw`text-black`}
          analytics-id="Change:contact form:Confirm Your Zipcode"
        >
          {_("Change")}
        </Button>
        <Button
          negative
          onClick={e => {
            dispatch(setActiveView("SelectDealerView"))
            tealiumNavClick(
              "Confirm Your Zip Code",
              "Confirm",
              trackTealEvent,
              contactDealerData,
              {},
              zip,
            )
            LocalStorageClient.write("confirmedZip", true)
            LocalStorageClient.delete("sourceZip")
            e.preventDefault()
          }}
          css={tw`text-black`}
          analytics-id="Confirm:contact form:Confirm Your Zipcode"
        >
          {_("Confirm")}
        </Button>
      </div>
    </FormPage>
  )
}

export default ConfirmZip
