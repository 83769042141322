import React from "react"
import { IconProps } from "./Icon.d"
import { processIconColor } from "../../../helpers"

const Share: React.FC<IconProps> = ({ color = "black", ...remainingProps }) => {
  const colorString = processIconColor(color)
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g id="icon_share_24" data-name="icon share 24">
        <path
          id="Vector_1"
          data-name="Vector 1"
          d="m7.75,9.06H.75v14h22v-14h-7"
          fill="none"
          stroke={colorString}
          strokeWidth="1.5"
        />
        <path
          id="Line_11"
          data-name="Line 11"
          d="m11.75,1.06v16"
          fill="none"
          stroke={colorString}
          strokeWidth="1.5"
        />
        <path
          id="Vector_2"
          data-name="Vector 2"
          d="m6.75,6.06L11.49,1.32l.26-.26.26.26,4.74,4.74"
          fill="none"
          stroke={colorString}
          strokeWidth="1.5"
        />
      </g>
    </svg>
  )
}

export default Share
