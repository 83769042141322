import { SeriesPageContextProps } from "./seriespage"

export enum ACTION_TYPES {
  SET_SERIES_PAGE_DATA,
  UPDATE_SERIES_PAGE_FILTER,
  UPDATE_SERIES_SELECTED_MODEL,
}

export const setSeriesPageData = (values: SeriesPageContextProps) => {
  return {
    type: ACTION_TYPES.SET_SERIES_PAGE_DATA,
    payload: values,
  }
}

export const updateSeriesFilter = (filter: number) => {
  return {
    type: ACTION_TYPES.UPDATE_SERIES_PAGE_FILTER,
    payload: filter,
  }
}

export const updateSelectedModel = (model: any) => {
  return {
    type: ACTION_TYPES.UPDATE_SERIES_SELECTED_MODEL,
    payload: model,
  }
}
