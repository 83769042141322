import { lazy, Suspense } from "react"
type IconProps = {
  name: keyof typeof iconComponents
  [key: string]: any
}

const iconComponents = {
  Audio: lazy(() => import("./Audio")),
  ConnectedServices: lazy(() => import("./ConnectedServices")),
  Electric: lazy(() => import("./Electric")),
  Dealer: lazy(() => import("./Dealer")),
  English: lazy(() => import("./English")),
  Spanish: lazy(() => import("./Spanish")),
  Exterior: lazy(() => import("./Exterior")),
  Interior: lazy(() => import("./Interior")),
  Saftey: lazy(() => import("./Saftey")),
  Specifications: lazy(() => import("./Specifications")),
  Checkmark: lazy(() => import("./Checkmark")),
  Battery: lazy(() => import("./Battery")),
  ChatBubble: lazy(() => import("./ChatBubble")),
  Chevron: lazy(() => import("./Chevron")),
  X: lazy(() => import("./X")),
  Heart: lazy(() => import("./Heart")),
  History: lazy(() => import("./History")),
  Filter: lazy(() => import("./Filter")),
  Instagram: lazy(() => import("./Instagram")),
  Menu: lazy(() => import("./Menu")),
  Clock: lazy(() => import("./Clock")),
  ExternalLink: lazy(() => import("./ExternalLink")),
  Star: lazy(() => import("./Star")),
  HalfStar: lazy(() => import("./HalfStar")),
  Globe: lazy(() => import("./Globe")),
  Search: lazy(() => import("./Search")),
  Refresh: lazy(() => import("./Refresh")),
  YouTube: lazy(() => import("./YouTube")),
  Pinterest: lazy(() => import("./Pinterest")),
  Facebook: lazy(() => import("./Facebook")),
  Twitter: lazy(() => import("./Twitter")),
  Phone: lazy(() => import("./Phone")),
  PhoneRinging: lazy(() => import("./PhoneRinging")),
  Location: lazy(() => import("./Location")),
  Oil: lazy(() => import("./Oil")),
  Coatings: lazy(() => import("./Coatings")),
  Rental: lazy(() => import("./Rental")),
  Tire: lazy(() => import("./Tire")),
  Tow: lazy(() => import("./Tow")),
  User: lazy(() => import("./User")),
  Warning: lazy(() => import("./Warning")),
  Expand: lazy(() => import("./Expand")),
  Fuel: lazy(() => import("./Fuel")),
  SaveSearch: lazy(() => import("./SaveSearch")),
  Winch: lazy(() => import("./Winch")),
  Notification: lazy(() => import("./Notification")),
  Marker: lazy(() => import("./Marker")),
  Lock: lazy(() => import("./Lock")),
  Bar: lazy(() => import("./Bar")),
  Loader: lazy(() => import("./Loader")),
  Directions: lazy(() => import("./Directions")),
  FAQs: lazy(() => import("./FAQs")),
  Manual: lazy(() => import("./Manual")),
  QuestionMarkCircle: lazy(() => import("./QuestionMarkCircle")),
  ProgressRing: lazy(() => import("./ProgressRing")),
  ArrowBlock: lazy(() => import("./ArrowBlock")),
  CloseBlock: lazy(() => import("./CloseBlock")),
  Wrench: lazy(() => import("./Wrench")),
  Help: lazy(() => import("./Help")),
  Info: lazy(() => import("./Info")),
  InfoInverted: lazy(() => import("./InfoInverted")),
  Learn: lazy(() => import("./Learn")),
  InfoOutlined: lazy(() => import("./InfoOutlined")),
  Language: lazy(() => import("./Language")),
  News: lazy(() => import("./News")),
  Pencil: lazy(() => import("./Pencil")),
  Success: lazy(() => import("./Success")),
  Upload: lazy(() => import("./Upload")),
  Inspection: lazy(() => import("./Inspection")),
  PartsService: lazy(() => import("./PartsService")),
  AirFilter: lazy(() => import("./AirFilter")),
  VehicleSearch: lazy(() => import("./VehicleSearch")),
  Value: lazy(() => import("./Value")),
  Style: lazy(() => import("./Style")),
  Question: lazy(() => import("./Question")),
  Protection: lazy(() => import("./Protection")),
  Performance: lazy(() => import("./Performance")),
  FindDealer: lazy(() => import("./FindDealer")),
  Maintenance: lazy(() => import("./Maintenance")),
  GenuineParts: lazy(() => import("./GenuineParts")),
  Fluids: lazy(() => import("./Fluids")),
  MapPin: lazy(() => import("./MapPin")),
  Efficient: lazy(() => import("./Efficient")),
  Dependable: lazy(() => import("./Dependable")),
  Comfort: lazy(() => import("./Comfort")),
  Brakes: lazy(() => import("./Brakes")),
  Wipers: lazy(() => import("./Wipers")),
  DisclaimerInfo: lazy(() => import("./DisclaimerInfo")),
  MapPinWithCircle: lazy(() => import("./MapPinWithCircle")),
  Mail: lazy(() => import("./Mail")),
  PaginationButton: lazy(() => import("./PaginationButton")),
  XSeries: lazy(() => import("./XSeries")),
  CheckMarkInCircle: lazy(() => import("./CheckMarkInCircle")),
  Standard: lazy(() => import("./Standard")),
  Available: lazy(() => import("./Available")),
  ZoomOut: lazy(() => import("./ZoomOut")),
  ZoomIn: lazy(() => import("./ZoomIn")),
  MatchFull: lazy(() => import("./MatchFull")),
  EvBattery: lazy(() => import("./EvBattery")),
  EvPluginInHybrid: lazy(() => import("./EvPluginInHybrid")),
  EvHybrid: lazy(() => import("./EvHybrid")),
  Share: lazy(() => import("./Share")),
  ShopParts: lazy(() => import("./ShopParts")),
  VehicleOnDesktop: lazy(() => import("./VehicleOnDesktop")),
}

const DynamicIcon = ({ name, ...props }: IconProps) => {
  const IconComponent = name ? iconComponents[name] : null
  if (!IconComponent) {
    return null
  }

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <IconComponent {...props} />
    </Suspense>
  )
}

export default DynamicIcon
