import React, { useContext, useEffect, useRef } from "react"
import { GlobalStyles } from "../GlobalStyles"
import { LayoutProps } from "./Layout.d"
import { HeaderNavigation } from "../../molecules/HeaderNavigation"
import { Footer } from "../../molecules/Footer"
import { StickyFooter } from "../../molecules/StickyFooter"
import { OutOfRangeModal } from "../../molecules/OutOfRangeModal"
import { useLocation } from "@reach/router"
import { ContactContext } from "../../../contexts/Contact"
import { openContactDealerModal } from "../../../contexts/Contact/actions"
import useZipCodeGo from "../../../hooks/Tealium/useZipCodeGo"

/**
 * A complete layout wrapper component to use around every page.
 *
 * @param children {ReactNode|ReachNodeArray}
 * @param {object} tealium An object that is passed to the useZipCodeGo custom hook, to provide data to ceertail Tealium event function calls.
 */
const Layout: React.FC<LayoutProps> = ({
  // templateSections,
  // templateSlug,
  children,
  tealium = { label: "", extraData: {}, pilotDealerCodes: [] },
  ...remainingProps
}) => {
  // Ref to access DOM for StickyFooter scroll-spy (can be used for any DOM access)
  const mainRef = useRef(null)

  const [{}, dispatch] = useContext(ContactContext)
  const location = useLocation()
  // Initialize useZipCodeGo hook for each page.
  useZipCodeGo(tealium.label, tealium.extraData, tealium.pilotDealerCodes)

  useEffect(() => {
    if (typeof document === "undefined") return

    const gatsbyFocusWrapper = document.getElementById("gatsby-focus-wrapper")
    if (gatsbyFocusWrapper) {
      gatsbyFocusWrapper.removeAttribute("style")
      gatsbyFocusWrapper.removeAttribute("tabIndex")
    }

    // Open contact form if contactForm=true query param exists
    const params = new URLSearchParams(location?.search)
    const contactFormParams = params?.get("contactForm")
    if (contactFormParams) {
      dispatch(openContactDealerModal())
    }
  }, [])

  return (
    <>
      <GlobalStyles />
      <OutOfRangeModal />
      <HeaderNavigation />
      <main ref={mainRef} {...remainingProps} id="main">
        {children}
      </main>
      <Footer />
      <StickyFooter
        mainRef={mainRef}
        // templateSections={templateSections}
        // templateSlug={templateSlug}
      />
    </>
  )
}

export default Layout
