import React, { useContext } from "react"
import tw from "twin.macro"
import { LanguageContext } from "../../../contexts/Language"
import { DealerDetailsPageContext } from "../../../templates/dealerDetails"

const DealerAwards: React.FC = () => {
  const { dealer } = useContext(DealerDetailsPageContext)
  const { _ } = useContext(LanguageContext)
  const certificationsToShow = [
    "CAB",
    "BOG",
    "PresidentsAward",
    "LEEDCertification",
  ]

  const certificationsToDisplay = dealer?.Certifications?.filter(
    (cert: { code: string; active: any }) =>
      certificationsToShow.includes(cert.code) && cert.active,
  )

  return (
    <>
      {certificationsToDisplay?.length > 0 && (
        <div css={[tw`bg-gray-100 rounded-lg m-0 mt-4 p-8`, tw`md:(m-4)`]}>
          <ul css={[tw`list-disc list-outside mb-4`]}>
            <span css={tw`text-2xl tracking-widest mb-2 block font-light`}>
              {_("Awards")}
            </span>
            {certificationsToDisplay?.map(
              (certification: { code: string; active: any; name: any }) => {
                return <li css={[tw`ml-4`]}>{certification.name}</li>
              },
            )}
          </ul>
        </div>
      )}
    </>
  )
}

export default DealerAwards
