import React, { useContext } from "react"
import tw from "twin.macro"
import { getCTALinkValue, parseDisclaimerBlocks } from "../../../helpers"
import Icon from "../../atoms/Icon"
import { css } from "@emotion/react"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { BannerContentProps } from "./Banner.d"
import { Link } from "../../atoms/Link"
import { LanguageContext } from "../../../contexts/Language"
import { DisclaimersContext } from "../../../contexts/Disclaimers"
import { toggleDisclaimersModal } from "../../../contexts/Disclaimers/actions"

const BannerContent: React.FC<BannerContentProps> = ({ bannerData }) => {
  // Tealium
  const { trackTealEvent } = useTealiumEvent()

  const subheadingHoverAnimation = css`
    @media (min-width: 1024px) {
      &:hover > .darkRed {
        ${tw`opacity-100 duration-300 translate-x-11`}
      }
      &:hover > .lighterRed {
        ${tw`opacity-100 delay-200 translate-x-8`}
      }
      &:hover > .lightestRed {
        ${tw`opacity-100 delay-100 translate-x-5`}
      }
    }
  `
  const subheadingFocusAnimation = css`
    @media (min-width: 1024px) {
      &:focus-visible > .darkRed {
        ${tw`opacity-100 duration-300 translate-x-11`}
      }
      &:focus-visible > .lighterRed {
        ${tw`opacity-100 delay-200 translate-x-8`}
      }
      &:focus-visible > .lightestRed {
        ${tw`opacity-100 delay-100 translate-x-5`}
      }
    }
  `
  const language = useContext(LanguageContext)
  const [state, dispatch] = useContext(DisclaimersContext)
  return (
    <section
      aria-label="Banner Section"
      css={[tw`flex-row mb-6`, tw`lg:(flex w-full items-center mb-0)`]}
    >
      <span
        css={[
          tw`uppercase block font-bold pr-1 md:text-sm xl:(text-base whitespace-nowrap) z-[9999]`,
          language?.language === "es"
            ? tw`text-[12px] lg:text-[10px] xl:(text-xs) 2xl:(text-base)`
            : tw`xl:text-base`,
          `color:${
            bannerData.text1Color ? `${bannerData.text1Color.hex}D9` : `black`
          }`,
        ]}
      >
        {bannerData.badgeText1 &&
          parseDisclaimerBlocks(bannerData.badgeText1, selection =>
            dispatch(toggleDisclaimersModal(selection)),
          )}
      </span>
      <span
        css={[
          tw`uppercase font-bold md:text-sm xl:text-base whitespace-nowrap z-[9999]`,
          language?.language === "es"
            ? tw`text-[12px] lg:text-[10px] xl:(text-xs)`
            : tw`xl:text-base`,
          `color:${
            bannerData.text2Color ? `${bannerData.text2Color.hex}D9` : `black`
          }`,
        ]}
      >
        {bannerData.badgeText2 &&
          parseDisclaimerBlocks(bannerData.badgeText2, selection =>
            dispatch(toggleDisclaimersModal(selection)),
          )}
      </span>

      <span
        css={[
          tw`block`,
          tw`lg:(text-sm pl-3 inline-block text-ellipsis z-[9999] whitespace-nowrap w-auto)`,
          tw`xl:(text-base w-auto inline-block)`,
          language?.language === "es"
            ? tw`text-[12px] lg:text-[10px] xl:(text-xs) 2xl:(text-base)`
            : tw`xl:text-base`,
          css`
            text-overflow: ellipsis;
          `,
        ]}
      >
        {bannerData.bannerText &&
          parseDisclaimerBlocks(bannerData.bannerText, selection =>
            dispatch(toggleDisclaimersModal(selection)),
          )}
      </span>
      <Link
        to={getCTALinkValue(bannerData.cta)}
        target={bannerData?.cta?.linkType === "external" ? "_blank" : "_self"}
        css={[
          tw`relative text-sm p-0 my-1 font-semibold whitespace-nowrap`,
          tw`lg:ml-2`,
          tw`xl:text-sm`,
          tw`focus-visible:outline-gray-50`,
          language?.language === "es"
            ? tw`lg:text-[10px] xl:(text-xs) 2xl:(text-base)`
            : tw`xl:text-base`,
          subheadingHoverAnimation,
          subheadingFocusAnimation,
        ]}
        onClick={() =>
          trackTealEvent({
            tealium_event: "nav_click",
            click_text: `top nav:headline`,
            link_href: getCTALinkValue(bannerData.cta),
            coupon_module_text: bannerData.cta.title,
          })
        }
        analytics-id={`headline:top nav:`}
      >
        {bannerData.cta.title}
        <Icon.Chevron
          direction="right"
          color="red-200"
          className="lightestRed"
          css={[
            tw`absolute bottom-0 right-2 h-3 translate-x-5 -translate-y-0.5 opacity-0`,
          ]}
        />
        <Icon.Chevron
          direction="right"
          color="red-300"
          className="lighterRed"
          css={[
            tw`absolute bottom-0 right-2 h-3 translate-x-5 -translate-y-0.5 opacity-0`,
          ]}
        />
        <Icon.Chevron
          direction="right"
          color="red-400"
          className="darkRed"
          css={[
            tw`absolute bottom-0 right-2 h-3 translate-x-5 -translate-y-0.5`,
          ]}
        />
      </Link>
    </section>
  )
}

export default BannerContent
