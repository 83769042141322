import React, { useContext, useState } from "react"
import { ViewProps } from "./ContactDealer.d"
import FormPage from "./FormPage"
import tw from "twin.macro"
import { LocationContext } from "../../../contexts/Location"
import InputField from "./InputField"
import { ContactContext } from "../../../contexts/Contact"
import { setActiveView } from "../../../contexts/Contact/actions"
import { LanguageContext } from "../../../contexts/Language"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { useTealiumContext } from "../../../contexts/Tealium"
import { tealiumNavClick } from "./TealiumHelpers"
import { LocalStorageClient } from "../../../clients/LocalStorageClient"

const DealerLocationView: React.FC<ViewProps> = ({ active }) => {
  const [{ zip }, _dispatch, updateLocationInfo] = useContext(LocationContext)
  const { _ } = useContext(LanguageContext)
  const zipIsConfirmed = LocalStorageClient.read("confirmedZip") || false

  const [{}, dispatch] = useContext(ContactContext)
  const [query, setQuery] = useState(null)
  const [showValidation, setShowValidation] = useState(false)
  // Tealium
  const { trackTealEvent } = useTealiumEvent()
  const { contactDealerData } = useTealiumContext()

  // Query is only required when zip code is not set.
  const handleSubmit = async () => {
    if (!zip && !query) {
      setShowValidation(true)
    } else if (zip && !query) {
      !zipIsConfirmed && LocalStorageClient.write("confirmedZip", true)
      setShowValidation(false)
      dispatch(setActiveView("SelectDealerView"))
    } else {
      !zipIsConfirmed && LocalStorageClient.write("confirmedZip", true)
      setShowValidation(false)
      try {
        const res = await updateLocationInfo(query)
        if (!res?.isOutOfRange?.value && res?.zip) {
          dispatch(setActiveView("SelectDealerView"))
          tealiumNavClick(
            "Enter ZIP, state, city, or dealer name",
            "Next",
            trackTealEvent,
            contactDealerData,
          )
        }
      } catch (err) {
        setShowValidation(true)
        console.log(err)
      }
    }
  }

  if (!active) return null

  return (
    <FormPage
      active={active}
      prevOnClick={
        zip
          ? e => {
              dispatch(setActiveView("ConfirmZip"))
              tealiumNavClick(
                "Enter ZIP, state, city, or dealer name",
                "Back",
                trackTealEvent,
                contactDealerData,
              )
              e.preventDefault()
            }
          : null
      }
      nextOnClick={handleSubmit}
      heading={_("Enter ZIP, state, city, or dealer name")}
      showFormHeading={false}
    >
      <InputField
        active={active}
        showVal={showValidation}
        type="text"
        fieldName="dealerLocationSearchQuery"
        label=""
        validationMessage={_(
          "Please enter a valid ZIP, state, city, or dealer name.",
        )}
        value={query}
        customOnChange={setQuery}
        css={[tw`max-w-[15rem]`]}
        heading="Enter ZIP, state, city, or dealer name"
      />
    </FormPage>
  )
}

export default DealerLocationView
