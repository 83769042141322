import React from "react"
import tw from "twin.macro"
import { ValidationPopoverProps } from "./ContactDealer.d"

const ValidationPopover: React.FC<ValidationPopoverProps> = ({
  showRequiredValidatioMsg,
  message,
  ...remainingProps
}) => {
  return (
    <section
      css={[
        tw`absolute bottom-4 left-1/2 -translate-x-1/2 overflow-hidden transition-all duration-300 w-auto md:(bottom-0)`,
        showRequiredValidatioMsg ? tw`max-h-[500px]` : tw`max-h-0`,
      ]}
      {...remainingProps}
    >
      <div
        css={[
          tw`bg-red-400 text-gray-50 text-xs leading-3 normal-case mx-auto mt-3 py-2 px-3 rounded-md`,
          // Pointed div
          tw`before:(absolute right-[calc(50% - 10px)] top-1.5 border-solid border-[10px] border-t-0 border-transparent border-b-red-400)`,
        ]}
      >
        {message}
      </div>
    </section>
  )
}

export default ValidationPopover
