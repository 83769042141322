export enum ACTION_TYPES {
  SET_CONFIRM_ZIP_MODAL_OPEN,
}

export const openConfirmZipModal = () => {
  return {
    type: ACTION_TYPES.SET_CONFIRM_ZIP_MODAL_OPEN,
    payload: true,
  }
}

export const closeConfirmZipModal = () => {
  return {
    type: ACTION_TYPES.SET_CONFIRM_ZIP_MODAL_OPEN,
    payload: false,
  }
}
