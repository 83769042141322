import tw from "twin.macro"
import { Series } from "../../../global"
import { SelectFieldProps } from "./ContactDealer.d"
import { Option, Select } from "../../atoms/Select"

const SelectField: React.FC<SelectFieldProps> = ({
  active,
  showVal,
  options,
  onChange,
  fieldName,
  label,
  validationMessage,
  value,
  disabled,
  maxDropdownHeight = null,
  ...remainingProps
}) => {
  const { analyticsId } = remainingProps
  return (
    <section
      css={[
        tw`relative max-w-full w-full`,
        tw`after:(invisible block border-b-2 absolute bottom-0 left-1/2 w-0 h-px transform -translate-x-1/2 transition-all duration-500)`,
        showVal
          ? tw`after:(border-red-400 visible w-full z-10)`
          : tw`after:(border-black)`,
        !active && tw`hidden`,
      ]}
      {...remainingProps}
    >
      <Select
        value={value}
        onChange={onChange}
        placeholder={label}
        red={disabled ? false : true}
        css={[
          tw`w-full normal-case text-gray-600 font-semibold text-lg pr-2 bg-transparent border-b border-black`,
        ]}
        maxDropdownHeight={maxDropdownHeight}
        analytics-id={analyticsId}
      >
        {options?.map((node: Series & { disabled?: boolean }, index) => (
          <Option
            key={`${node?.name}-${index}`}
            label={node?.name}
            value={node?.name}
            css={[node?.disabled && tw`pointer-events-none opacity-60`]}
          />
        ))}
      </Select>
      {/* Series option validation popover */}
      <section
        css={[
          tw`absolute -bottom-9 left-1/2 -translate-x-1/2 overflow-hidden transition-all duration-300`,
          showVal ? tw`max-h-[50px]` : tw`max-h-0`,
        ]}
      >
        <div
          css={[
            tw`bg-red-400 text-gray-50 text-xs leading-3 normal-case mx-auto mt-3 py-2 px-3 rounded-md`,
            // Pointed div
            tw`before:(absolute right-[calc(50% - 10px)] top-1.5 border-solid border-[10px] border-t-0 border-transparent border-b-red-400)`,
          ]}
        >
          {validationMessage}
        </div>
      </section>
    </section>
  )
}

export default SelectField
