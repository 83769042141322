import React from "react"
import tw from "twin.macro"
import { CardProps } from "./Card.d"

/**
 * Generic card component with shadow and optional header image.
 *
 * @component
 * @param {CardProps} props
 * @param {0|1|2|3|4|5} props.shadowLevel - Depth of the card's shadow
 * @param {ReactElement<ImageProps>} props.image - Image component to display at top of card
 * @param {ReactNode} props.children - Contents of the card body
 *
 * @returns <div></div>
 */
const Card: React.FC<CardProps> = ({
  shadowLevel = 1,
  image,
  children,
  ...remainingProps
}) => {
  // This seems convoluted, but is the only way twin.macro could seem to handle it
  const shadowLevelClasses = [
    tw`shadow-none`,
    tw`shadow-1`,
    tw`shadow-2`,
    tw`shadow-3`,
    tw`shadow-4`,
    tw`shadow-5`,
  ]
  const shadowClass = shadowLevelClasses[shadowLevel - 1]

  return (
    <div
      css={[tw`block rounded-lg overflow-hidden`, shadowClass]}
      {...remainingProps}
    >
      {image}
      <div css={[tw`p-4 w-full h-full md:(p-5)`]}>{children}</div>
    </div>
  )
}

export default Card
