import React from "react"
import DynamicIcon from "./DynamicIcon"

const iconNames: string[] = [
  "Audio",
  "ConnectedServices",
  "Electric",
  "Dealer",
  "English",
  "Spanish",
  "Exterior",
  "Interior",
  "Saftey",
  "Specifications",
  "Checkmark",
  "Battery",
  "ChatBubble",
  "Chevron",
  "Compare",
  "X",
  "Heart",
  "History",
  "Filter",
  "Instagram",
  "Menu",
  "Clock",
  "ExternalLink",
  "Star",
  "HalfStar",
  "Globe",
  "Search",
  "Refresh",
  "YouTube",
  "Pinterest",
  "Facebook",
  "Twitter",
  "Phone",
  "PhoneRinging",
  "Location",
  "Oil",
  "Coatings",
  "Rental",
  "Tire",
  "Tow",
  "User",
  "Warning",
  "Expand",
  "Fuel",
  "SaveSearch",
  "Winch",
  "Notification",
  "Marker",
  "Lock",
  "Bar",
  "Loader",
  "Directions",
  "FAQs",
  "Manual",
  "QuestionMarkCircle",
  "ProgressRing",
  "ArrowBlock",
  "CloseBlock",
  "Wrench",
  "Help",
  "Info",
  "InfoInverted",
  "Learn",
  "InfoOutlined",
  "Language",
  "News",
  "Pencil",
  "Success",
  "Upload",
  "Inspection",
  "PartsService",
  "AirFilter",
  "VehicleSearch",
  "Value",
  "Style",
  "Question",
  "Protection",
  "Performance",
  "FindDealer",
  "Maintenance",
  "GenuineParts",
  "Fluids",
  "MapPin",
  "Efficient",
  "Dependable",
  "Comfort",
  "Brakes",
  "Wipers",
  "DisclaimerInfo",
  "MapPinWithCircle",
  "Mail",
  "PaginationButton",
  "XSeries",
  "CheckMarkInCircle",
  "Standard",
  "Available",
  "ZoomOut",
  "ZoomIn",
  "MatchFull",
  "EvBattery",
  "EvPluginInHybrid",
  "EvHybrid",
  "Share",
  "ShopParts",
  "VehicleOnDesktop",
]

type IconType = {
  [key: string]: React.FC<any>
}

const Icon: IconType = {}

iconNames.forEach((name: string) => {
  Icon[name] = props => <DynamicIcon name={name} {...props} />
})

export default Icon

// Export each icon individually
export const Audio = Icon.Audio
export const ConnectedServices = Icon.ConnectedServices
export const Electric = Icon.Electric
export const Dealer = Icon.Dealer
export const English = Icon.English
export const Spanish = Icon.Spanish
export const Exterior = Icon.Exterior
export const Interior = Icon.Interior
export const Saftey = Icon.Saftey
export const Specifications = Icon.Specifications
export const Checkmark = Icon.Checkmark
export const Battery = Icon.Battery
export const ChatBubble = Icon.ChatBubble
export const Chevron = Icon.Chevron
export const Compare = Icon.Compare
export const X = Icon.X
export const Heart = Icon.Heart
export const History = Icon.History
export const Filter = Icon.Filter
export const Instagram = Icon.Instagram
export const Menu = Icon.Menu
export const Clock = Icon.Clock
export const ExternalLink = Icon.ExternalLink
export const Star = Icon.Star
export const HalfStar = Icon.HalfStar
export const Globe = Icon.Globe
export const Search = Icon.Search
export const Refresh = Icon.Refresh
export const YouTube = Icon.YouTube
export const Pinterest = Icon.Pinterest
export const Facebook = Icon.Facebook
export const Twitter = Icon.Twitter
export const Phone = Icon.Phone
export const PhoneRinging = Icon.PhoneRinging
export const Location = Icon.Location
export const Oil = Icon.Oil
export const Coatings = Icon.Coatings
export const Rental = Icon.Rental
export const Tire = Icon.Tire
export const Tow = Icon.Tow
export const User = Icon.User
export const Warning = Icon.Warning
export const Expand = Icon.Expand
export const Fuel = Icon.Fuel
export const SaveSearch = Icon.SaveSearch
export const Winch = Icon.Winch
export const Notification = Icon.Notification
export const Marker = Icon.Marker
export const Lock = Icon.Lock
export const Bar = Icon.Bar
export const Loader = Icon.Loader
export const Directions = Icon.Directions
export const FAQs = Icon.FAQs
export const Manual = Icon.Manual
export const QuestionMarkCircle = Icon.QuestionMarkCircle
export const ProgressRing = Icon.ProgressRing
export const ArrowBlock = Icon.ArrowBlock
export const CloseBlock = Icon.CloseBlock
export const Wrench = Icon.Wrench
export const Help = Icon.Help
export const Info = Icon.Info
export const InfoInverted = Icon.InfoInverted
export const Learn = Icon.Learn
export const InfoOutlined = Icon.InfoOutlined
export const Language = Icon.Language
export const News = Icon.News
export const Pencil = Icon.Pencil
export const Success = Icon.Success
export const Upload = Icon.Upload
export const Inspection = Icon.Inspection
export const PartsService = Icon.PartsService
export const AirFilter = Icon.AirFilter
export const VehicleSearch = Icon.VehicleSearch
export const Value = Icon.Value
export const Style = Icon.Style
export const Question = Icon.Question
export const Protection = Icon.Protection
export const Performance = Icon.Performance
export const FindDealer = Icon.FindDealer
export const Maintenance = Icon.Maintenance
export const GenuineParts = Icon.GenuineParts
export const Fluids = Icon.Fluids
export const MapPin = Icon.MapPin
export const Efficient = Icon.Efficient
export const Dependable = Icon.Dependable
export const Comfort = Icon.Comfort
export const Brakes = Icon.Brakes
export const Wipers = Icon.Wipers
export const DisclaimerInfo = Icon.DisclaimerInfo
export const MapPinWithCircle = Icon.MapPinWithCircle
export const Mail = Icon.Mail
export const PaginationButton = Icon.PaginationButton
export const XSeries = Icon.XSeries
export const CheckMarkInCircle = Icon.CheckMarkInCircle
export const Standard = Icon.Standard
export const Available = Icon.Available
export const ZoomOut = Icon.ZoomOut
export const ZoomIn = Icon.ZoomIn
export const MatchFull = Icon.MatchFull
export const EvBattery = Icon.EvBattery
export const EvPluginInHybrid = Icon.EvPluginInHybrid
export const EvHybrid = Icon.EvHybrid
export const Share = Icon.Share
export const ShopParts = Icon.ShopParts
export const VehicleOnDesktop = Icon.VehicleOnDesktop
