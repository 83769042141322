import React from "react"
import tw from "twin.macro"
import { ModalProps } from "./Modal.d"

/**
 * Modal background opacity dark
 *
 * @param {boolean} props.backdropStyled - Style the background of the modal
 *
 * @returns {JSX.Element} <Backdrop/>
 */

const Backdrop: React.FC<ModalProps> = ({
  open,
  onClose,
  backdropStyled,
  children,
  fromDealersNearYou,
  zIndex,
  ...remainingProps
}) => {
  return (
    <div
      css={[
        tw`flex justify-center items-center transition bg-black bg-opacity-0 focus:(outline-none) hover:(outline-none)`,
        fromDealersNearYou
          ? tw` w-full items-start lg:items-center h-[103%] lg:h-full absolute z-[40] left-0 -top-8 lg:top-0 py-8`
          : tw` w-screen h-screen fixed z-[9999] left-0 top-0`,
        open && tw`bg-opacity-70`,
        backdropStyled && open && tw`bg-opacity-100`,
      ]}
      onKeyDown={e => {
        if (e.key === "Escape") onClose()
      }}
      style={zIndex && { zIndex: zIndex }}
      {...remainingProps}
    >
      {children}
    </div>
  )
}

export default Backdrop
