import React, { createContext, useReducer } from "react"
import { SeriesAccessoriesProviderProps } from "./seriesaccessories"
import seriesAccessoriesReducer from "./reducers"

const initialState: SeriesAccessoriesProviderProps = {
  page: null,
  accessories: null,
  filteredAccessories: null,
  setFilteredAccessories: null,
  selectedCategories: null,
  setSelectedCategories: null,
  selectedYears: null,
  setSelectedYears: null,
  selectedModelGroup: null,
  setSelectedModelGroup: null,
  models: null,
  selectedAccyType: null,
  seriesSlug: "",
}

export const SeriesAccessoriesContext = createContext<
  [SeriesAccessoriesProviderProps, React.Dispatch<any>]
>([initialState, () => {}])

export const SeriesAccessoriesContextProvider = ({ children, pageType }) => {
  const [seriesState, dispatch] = useReducer(
    seriesAccessoriesReducer,
    initialState,
  )

  return (
    <SeriesAccessoriesContext.Provider value={[seriesState, dispatch]}>
      {children}
    </SeriesAccessoriesContext.Provider>
  )
}
