import React, { useContext } from "react"
import tw from "twin.macro"
import { DisclaimersContext } from "../../../contexts/Disclaimers"
import { Modal } from "../../atoms/Modal"
import { DisclaimersModalProps } from "./DisclaimersModal.d"
import { toggleDisclaimersModal } from "../../../contexts/Disclaimers/actions"
import { LanguageContext } from "../../../contexts/Language"

const DisclaimersModal = ({}: DisclaimersModalProps): JSX.Element => {
  const [{ disclaimers, open }, dispatch] = useContext(DisclaimersContext)
  const { language } = useContext(LanguageContext)

  return (
    <>
      <Modal
        open={open}
        onClose={() => dispatch(toggleDisclaimersModal())}
        css={[
          tw`w-full max-w-lg lg:max-w-[60rem] max-h-[calc(100vh - 60px)] overflow-y-scroll`,
        ]}
      >
        <div css={[tw`px-6 pt-10 pb-4 w-full`]}>
          <ol css={[tw`p-0 py-4`]}>
            {disclaimers.map(
              (
                { code, active, disclaimer, disclaimer_en, disclaimer_es },
                i,
              ) => {
                let disclaimerText
                if (language === "es") {
                  disclaimerText = disclaimer ? disclaimer : disclaimer_es
                } else {
                  disclaimerText = disclaimer ? disclaimer : disclaimer_en
                }

                if (!disclaimerText) return null
                return (
                  <li
                    css={[
                      tw`py-1 px-2 rounded flex`,
                      active && tw`bg-gray-200`,
                    ]}
                    data-disclaimer-code={code}
                    key={`disclaimer-${code}-${i}`}
                  >
                    <span css={[tw`mr-2`]}>{i + 1}.</span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: disclaimerText,
                      }}
                    />
                  </li>
                )
              },
            )}
          </ol>
        </div>
      </Modal>
    </>
  )
}

export default DisclaimersModal
