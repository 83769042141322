import React, { createContext, useReducer } from "react"
import { SeriesPageContextProps } from "./seriespage"
import seriesPageReducer from "./reducers"

const initialState: SeriesPageContextProps = {
  page: null,
  accessories: null,
  series: null,
  models: null,
  vehicle: null,
  selectedOfferTypes: null,
  setSelectedOfferTypes: null,
  selectedFilter: null,
  setSelectedFilter: null,
  selectedModel: null,
}

export const SeriesPageContext = createContext<
  [SeriesPageContextProps, React.Dispatch<any>]
>([initialState, () => {}])

export const SeriesPageContextProvider = ({ children, pageType }) => {
  const [seriesState, dispatch] = useReducer(seriesPageReducer, initialState)

  return (
    <SeriesPageContext.Provider value={[seriesState, dispatch]}>
      {children}
    </SeriesPageContext.Provider>
  )
}
