import { LocationState } from "./location.d"
import { ACTION_TYPES } from "./actions"

export const defaultState: LocationState = {
  zip: null,
  mapSearchZip: null,
  latLng: null,
  city: null,
  state: null,
  dealers: null,
  selectedDealer: null,
  dealersLoading: false,
  radius: 25,
  isOutOfRange: {
    value: false,
    zip: null,
    state: null,
  },
  fetchingLocationData: false,
  errorMessage: null,
}

const locationReducer = (
  state: LocationState,
  action: { type: ACTION_TYPES; payload?: any },
) => {
  switch (action.type) {
    case ACTION_TYPES.SET_ZIP:
      return {
        ...state,
        zip: action.payload,
        mapSearchZip: null,
      }
    case ACTION_TYPES.SET_MAP_SEARCH_ZIP:
      return {
        ...state,
        mapSearchZip: action.payload,
      }
    case ACTION_TYPES.SET_LATLNG:
      return {
        ...state,
        latLng: action.payload,
      }
    case ACTION_TYPES.SET_CITY:
      return {
        ...state,
        city: action.payload,
      }
    case ACTION_TYPES.SET_STATE:
      return {
        ...state,
        state: action.payload,
      }
    case ACTION_TYPES.SET_RADIUS:
      console.info("Setting radius to", action.payload)
      return {
        ...state,
        radius: action.payload,
      }
    case ACTION_TYPES.SET_DEALERS:
      return {
        ...state,
        dealers: action.payload,
      }
    case ACTION_TYPES.TOGGLE_DEALERS_LOADING:
      return {
        ...state,
        dealersLoading: !state.dealersLoading,
      }
    case ACTION_TYPES.ADD_DEALERS:
      return {
        ...state,
        dealers: [...state.dealers, ...action.payload],
      }
    case ACTION_TYPES.SET_SELECTED_DEALER:
      return {
        ...state,
        selectedDealer: state.dealers.filter(
          dealer => dealer.Id === action.payload,
        )[0],
      }
    case ACTION_TYPES.REMOVE_SELECTED_DEALER:
      return {
        ...state,
        selectedDealer: null,
      }
    case ACTION_TYPES.SET_MULTIPLE:
      return {
        ...state,
        ...action.payload,
      }
    case ACTION_TYPES.SET_OUT_OF_RANGE:
      return {
        ...state,
        isOutOfRange: action.payload,
      }
    case ACTION_TYPES.SET_FETCHING_LOCATION_DATA:
      return {
        ...state,
        fetchingLocationData: action.payload,
      }
    case ACTION_TYPES.SET_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: action.payload,
      }
    default:
      throw new Error()
  }
}

export default locationReducer
