import React, { useRef } from "react"
import tw from "twin.macro"
import Button from "../../atoms/Button/Button"
import { StyledComponent } from "@emotion/styled"
import { IButtonProps } from "../../atoms/Button"
import { CheckboxFieldProps } from "./ContactDealer.d"
import useComponentId from "../../../hooks/useIdGenerator"

export const ButtonDiv: StyledComponent<IButtonProps> =
  Button.withComponent("div")

const CheckboxField: React.FC<CheckboxFieldProps> = ({
  label,
  value,
  checked,
  handleCheck,
  handleUncheck,
}) => {
  const inputRef = useRef()
  const uuid = useComponentId()

  return (
    <label
      css={[tw`text-black cursor-pointer p-4`]}
      htmlFor={`${label}-${uuid}`}
    >
      <input
        ref={inputRef}
        type="checkbox"
        id={`${label}-${uuid}`}
        css={tw`appearance-none`}
        value={value}
        onChange={e => {
          if (e?.target?.checked) {
            handleCheck(e.target.value)
          } else {
            handleUncheck(e.target.value)
          }
        }}
        className="peer"
        checked={checked}
      />
      <ButtonDiv
        negative
        css={[
          tw`text-black cursor-pointer`,
          inputRef?.current?.checked && tw`bg-red-400 text-white`,
        ]}
      >
        {label}
      </ButtonDiv>
    </label>
  )
}

export default CheckboxField
